
.Toastify__toast--info {
    background-color: #FFED38;
    color:black
}

.Toastify__close-button {
    color:black
}

.Toastify__progress-bar {
    color:black
}